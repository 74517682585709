import * as React from 'react';
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Image,
  Icon,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'

import { motion } from 'framer-motion'
import ViewDemoBox from './ViewDemoBox'
import Header from './Header';

function LandingPage() {
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const toast = useToast();

  const handleDemoClick = () => {
    if (email && phone) {
      console.log('Show the demo to the user with email:', email, 'and phone:', phone);
      toast({
        title: 'Demo accessed.',
        description: "We've received your details and will show you the demo.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Information is required.',
        description: 'Please enter your email and phone number to view the demo.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
    <Header />
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 5, md: 10 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <Heading
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '4xl', md: '5xl', lg: '6xl' }}
            lineHeight={'110%'}>
            Digital <Text as={'span'} style={{ textDecoration: 'underline', textDecorationColor: '#d1415e', textDecorationThickness: '5px', textUnderlineOffset: '6px', display: 'inline', borderBottom: '3px wavy red' }}>menu</Text> for<br />
            <Text as={'span'} color={'green.400'}>
              your Restaurant
            </Text>
          </Heading>
          <Text color={'gray.500'}>
          All you need to do is take a photo of your menu and we will leverage the power of AI to create a beautiful looking digital menu with images.

          </Text>
          <Stack >
          <ViewDemoBox />
          </Stack>
          
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Blob
            w={'150%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            left={0}
            zIndex={-1}
            color={useColorModeValue('green.50', 'green.400')}
          />
          <motion.div
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5 }}
        style={{
          zIndex: -1,
        }}
      >
          <Box
            bg="black"
            borderRadius="xl"
            border="10px solid black"
            position="relative"
            display="flex"
            boxShadow="2xl"
            overflow="hidden"
            width="300px"
            zIndex="-1"
          >
            <Box left="50%" transform="translateX(-50%)" width="30%" height="5px" bg="black" borderRadius="sm"></Box>
            <Image src="demo-compressed.jpg" alt="Mobile App" fit="cover" h="full" w="full" width="500px" />
          </Box>
        </motion.div>
        </Flex>
      </Stack>
    </Container>
  </>
  )
}

const Blob = (props) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default LandingPage;
