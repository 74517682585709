import React from 'react';
import {
  Box,
  Heading,
  Stack,
  Input,
  Button,
  Text,
} from '@chakra-ui/react';

const ViewDemoBox = ({ email, setEmail, phone, setPhone, handleDemoClick }) => {

  return (
  
    <Stack
    bg={'gray.50'}
    rounded={'xl'}
    p={{ base: 4, sm: 6, md: 8 }}
    spacing={{ base: 8 }}
    maxW={{ lg: 'lg' }}>
    <Stack spacing={4}>
      <Heading
        color={'gray.800'}
        lineHeight={1.1}
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
        See your demo now
        <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
          !
        </Text>
      </Heading>
      <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
        Demo food order will be received on your phone number
      </Text>
    </Stack>
    <Box as={'form'} mt={1}>
      <Stack spacing={4}>
        <Input
          placeholder="Full Name"
          bg={'gray.100'}
          border={0}
          color={'gray.500'}
          _placeholder={{
            color: 'gray.500',
          }}
        />
        <Input
          placeholder="Email"
          bg={'gray.100'}
          border={0}
          color={'gray.500'}
          _placeholder={{
            color: 'gray.500',
          }}
        />
        <Input
          placeholder="Phone number"
          bg={'gray.100'}
          border={0}
          color={'gray.500'}
          _placeholder={{
            color: 'gray.500',
          }}
        />
      </Stack>
      <Button
        fontFamily={'heading'}
        mt={8}
        w={'full'}
        bgGradient="linear(to-r, red.400,pink.400)"
        color={'white'}
        _hover={{
          bgGradient: 'linear(to-r, red.400,pink.400)',
          boxShadow: 'xl',
        }}>
        View Demo
      </Button>
    </Box>
    form
    </Stack>
  );
};

export default ViewDemoBox;
