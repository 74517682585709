import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Link,
  Image,
  IconButton,
  useColorModeValue,
  useColorMode,
  Spacer,
  Icon,
} from '@chakra-ui/react';

import { FaSun, FaMoon } from 'react-icons/fa';
import { MdWbSunny, MdNightsStay, MdRestaurant } from 'react-icons/md';


const Header = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const blueHeadingColor = useColorModeValue('blue.500', 'blue.200')
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex
      bg={bgColor}
      color={textColor}
      py={{ base: 2 }}
      px={{ base: 4 }}
      align="center"
      justify="space-between"
    >
      <Flex align="center">
  <Icon as={MdRestaurant} boxSize={8} color="gray.600" />
  <Heading
    size="md" // Changed size to "md" for smaller size
    fontFamily="Montserrat, sans-serif"
    fontWeight="bold"
    letterSpacing="wide"
    ml={2}
  >
    <span style={{ color: '#1d57cc' }}>Prana</span>.menu
  </Heading>
</Flex>


      <Spacer />
      <Flex align="center">
        <Link
          color={useColorModeValue('green.500', 'green.200')}
          href="/about"
          fontWeight="bold"
          mr={4}
        >
          About Us
        </Link>
        <IconButton
          aria-label="Toggle theme"
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
          isRound={true}
          size="md"
          alignSelf="flex-end"
          boxShadow="base"
          bg={colorMode === 'light' ? 'grey.300' : 'gray.600'}
          color={colorMode === 'light' ? 'gray.800' : 'yellow.300'}
          _hover={{
            bg: colorMode === 'light' ? 'yellow.400' : 'gray.500',
          }}
        />
      </Flex>
    </Flex>
  );
};

export default Header;
